import * as React from 'react';

import Layout from '../../components/layout';

const Basic = () => (
  <div className="pb-16 mt-8 bg-white sm:mt-12 sm:pb-20 lg:pb-28">
    <div className="relative">
      <div className="absolute inset-0 bg-gray-100 h-1/2" />
      <div className="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg lg:max-w-none lg:flex">
          <div className="px-6 py-8 bg-white lg:flex-shrink-1 lg:p-12">
            <h3 className="text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9">
              Basic
            </h3>
            <p className="mt-6 text-base leading-6 text-gray-500">
              The easiest way to get started. Choose Sales, Invoicing, CRM, or
              another basic app to get up and running, so you can get familiar
              with Odoo. And once you&apos;ve comfortably integrated Odoo into
              your operation, make your plan to add additional apps in an order
              that works for you.
            </p>
            <div className="mt-8">
              <div className="flex items-center">
                <h4 className="flex-shrink-0 pr-4 text-sm font-semibold leading-5 tracking-wider text-teal-600 uppercase bg-white">
                  What&apos;s included
                </h4>
                <div className="flex-1 border-t-2 border-gray-200" />
              </div>
              <ul className="mt-8 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:row-gap-5">
                <li className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Project management
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Email & phone support
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Training & Coaching
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Configuration
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Basic apps (excludes Inventory, Manufacturing, Accounting)
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="px-6 py-8 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
            <p className="text-lg font-medium leading-6 text-gray-900">
              Per app
            </p>
            <div className="flex items-center justify-center mt-4 text-5xl font-extrabold leading-none text-gray-900">
              <span>$2,500</span>
              <span className="ml-3 text-xl font-medium leading-7 text-gray-500">
                USD
              </span>
            </div>
            <div className="mt-6">
              <div className="rounded-md shadow">
                <a
                  href="#"
                  className="flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-gray-900 border border-transparent rounded-md hover:bg-gray-800 focus:outline-none focus:ring"
                >
                  Let&apos;s get started!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Standard = () => (
  <div className="pb-16 mt-8 bg-white sm:mt-12 sm:pb-20 lg:pb-28">
    <div className="relative">
      <div className="absolute inset-0 bg-gray-100 h-1/2" />
      <div className="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg lg:max-w-none lg:flex">
          <div className="px-6 py-8 bg-white lg:flex-shrink-1 lg:p-12">
            <h3 className="text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9">
              Standard
            </h3>
            <p className="mt-6 text-base leading-6 text-gray-500">
              When you&apos;re ready to add one of Odoo&apos;s advanced apps
              (Inventory, Manufacturing, Accounting), use our standard plan,
              wich also covers assistance with importing your data from your
              existing applications.
            </p>
            <div className="mt-8">
              <div className="flex items-center">
                <h4 className="flex-shrink-0 pr-4 text-sm font-semibold leading-5 tracking-wider text-teal-600 uppercase bg-white">
                  What&apos;s included
                </h4>
                <div className="flex-1 border-t-2 border-gray-200" />
              </div>
              <ul className="mt-8 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:row-gap-5">
                <li className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Project management
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Email & phone support
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Training & Coaching
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Configuration
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Data Import Assistance
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Advanced apps (Inventory, Manufacturing, Accounting)
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="px-6 py-8 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
            <p className="text-lg font-medium leading-6 text-gray-900">
              Per app
            </p>
            <div className="flex items-center justify-center mt-4 text-5xl font-extrabold leading-none text-gray-900">
              <span>$5,000</span>
              <span className="ml-3 text-xl font-medium leading-7 text-gray-500">
                USD
              </span>
            </div>
            <div className="mt-6">
              <div className="rounded-md shadow">
                <a
                  href="#"
                  className="flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-gray-900 border border-transparent rounded-md hover:bg-gray-800 focus:outline-none focus:ring"
                >
                  Let&apos;s get started!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Custom = () => (
  <div className="pb-16 mt-8 bg-white sm:mt-12 sm:pb-20 lg:pb-28">
    <div className="relative">
      <div className="absolute inset-0 bg-gray-100 h-1/2" />
      <div className="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg lg:max-w-none lg:flex">
          <div className="px-6 py-8 bg-white lg:flex-shrink-1 lg:p-12">
            <h3 className="text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9">
              Custom
            </h3>
            <p className="mt-6 text-base leading-6 text-gray-500">
              All the features of the standard plan, but also includes
              customization of the app, e.g. forms, reports, workflows. (You
              must also license the Odoo Studio app.)
            </p>
            <div className="mt-8">
              <div className="flex items-center">
                <h4 className="flex-shrink-0 pr-4 text-sm font-semibold leading-5 tracking-wider text-teal-600 uppercase bg-white">
                  What&apos;s included
                </h4>
                <div className="flex-1 border-t-2 border-gray-200" />
              </div>
              <ul className="mt-8 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:row-gap-5">
                <li className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Project management
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Email & phone support
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Training & Coaching
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Configuration
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Data Import Assistance
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    All apps
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Customizations &mdash; forms, reports, workflows
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="px-6 py-8 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
            <p className="text-lg font-medium leading-6 text-gray-900">
              Starting at
            </p>
            <div className="flex items-center justify-center mt-4 text-5xl font-extrabold leading-none text-gray-900">
              <span>$7,500</span>
              <span className="ml-3 text-xl font-medium leading-7 text-gray-500">
                USD
              </span>
            </div>
            <div className="mt-6">
              <div className="rounded-md shadow">
                <a
                  href="#"
                  className="flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-gray-900 border border-transparent rounded-md hover:bg-gray-800 focus:outline-none focus:ring"
                >
                  Let&apos;s get started!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Pro = () => (
  <div className="pb-16 mt-8 bg-white sm:mt-12 sm:pb-20 lg:pb-28">
    <div className="relative">
      <div className="absolute inset-0 bg-gray-100 h-1/2" />
      <div className="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg lg:max-w-none lg:flex">
          <div className="px-6 py-8 bg-white lg:flex-shrink-1 lg:p-12">
            <h3 className="text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9">
              Pro
            </h3>
            <p className="mt-6 text-base leading-6 text-gray-500">
              For situations that require the Odoo app itself to be modified at
              the code level.
            </p>
            <div className="mt-8">
              <div className="flex items-center">
                <h4 className="flex-shrink-0 pr-4 text-sm font-semibold leading-5 tracking-wider text-teal-600 uppercase bg-white">
                  What&apos;s included
                </h4>
                <div className="flex-1 border-t-2 border-gray-200" />
              </div>
              <ul className="mt-8 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:row-gap-5">
                <li className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Project management
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Email & phone support
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Training & Coaching
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Configuration
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Data Import Assistance
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    All apps
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Customizations &mdash; forms, reports, workflows (requires
                    Odoo Studio app)
                  </p>
                </li>
                <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-teal-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700">
                    Development (code)
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="px-6 py-8 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
            <p className="text-lg font-medium leading-6 text-gray-900">
              Starting at
            </p>
            <div className="flex items-center justify-center mt-4 text-5xl font-extrabold leading-none text-gray-900">
              <span>$15,000</span>
              <span className="ml-3 text-xl font-medium leading-7 text-gray-500">
                USD
              </span>
            </div>
            <div className="mt-6">
              <div className="rounded-md shadow">
                <a
                  href="#"
                  className="flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-gray-900 border border-transparent rounded-md hover:bg-gray-800 focus:outline-none focus:ring"
                >
                  Let&apos;s get started!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default () => (
  <Layout>
    <div className="bg-gray-100">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold leading-9 text-gray-900 sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
              Simple no-tricks pricing
            </h2>
            <p className="mt-4 text-xl leading-7 text-gray-600">
              We prefer to quote fixed prices. We assess each project
              individually, but here are some examples.
            </p>
          </div>
        </div>
      </div>
      <Basic />
      <Standard />
      <Custom />
      <Pro />
    </div>
  </Layout>
);
